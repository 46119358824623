import React, { useState } from "react";
import RequestAction from "../../../modal/group/RequestAction";
import moment from 'moment'


import {
   Box,
   Flex,
   Table,
   Thead,
   Tbody,
   Center,
   Select,
   Tr,
   Th,
   Td,
   TableContainer,
   Text,
   Checkbox,
   Avatar,
} from "@chakra-ui/react";
import SearchField from "components/search-field/SearchField";


const NewRequestTable = ({ columns = [], data = [] }) => {
   const [localList, setLocalList] = useState(data)


   return (
      <div>
         <Flex flexDir="row" justifyContent="space-between" alignItems="center" mb='2em'>
            <SearchField placeholder="Search group" />

            <Box>
               <div className="flex items-center justify-between  ">
                  <Select
                     placeholder="All-time"
                     borderColor="#5D1AB2"
                     width="120px"
                     variant="outline"
                     color='#5D1AB2'
                     fontWeight={600}
                     cursor='pointer'
                     _hover={{ border: '1px solid #5D1AB2' }}
                  >
                     <option value="option1">Option 1</option>
                     <option value="option2">Option 2</option>
                     <option value="option3">Option 3</option>
                  </Select>
               </div>
            </Box>
         </Flex>
         <Box overflowY={'scroll'} h={'38vh'}>
            <TableContainer>
               <Table variant="simple">
                  <Thead>
                     <Tr>
                        {columns?.map(column => (
                           <Th key={column.key}>{column.title}</Th>
                        ))}
                     </Tr>
                  </Thead>
                  <Tbody fontSize={14}>
                     {data.length ? 
                        localList?.map((request, index) => (
                           <Tr>
                              <Td color="#5D1AB2">
                                 <Box>
                                    <Checkbox>{++index}</Checkbox>
                                 </Box>
                              </Td>
                              <Td>
                                 <Box>
                                    <Flex align="center">
                                       <Avatar src={''} name={request.sender.profile.full_name} size='xs' mr={2} />
                                       <Text fontWeight={700} color="#5D1AB2">{request.sender.profile.full_name}</Text>
                                    </Flex>
                                 </Box>
                              </Td>
                              <Td color="#5D1AB2">{request.sender.email}</Td>
                              <Td color="#5D1AB2">{request.sender.profile.mobile_phone}</Td>
                              <Td color="#5D1AB2">{''}</Td>
                              <Td color="#5D1AB2">{moment(request.updated_at).utc().format('DD-MM-YYYY')}</Td>
                              <Td color="#5D1AB2">
                                 <Center>
                                       <RequestAction request={request} setLocalList={setLocalList} data={data} />
                                 </Center>
                              </Td>
                           </Tr>
                        )) :
                        <Center>
                           <Text pt={6} fontWeight={700} color='#8975A2'>No group requests</Text>
                        </Center>
                     }
                  </Tbody>
               </Table>
            </TableContainer>
         </Box>
      </div>
   );
};

export default NewRequestTable;
