import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { InputField, ValidationError, CloseButton, Spinner } from 'components';

//APIs from react-form-hooks for form validations
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

//Schemas
import { createSubgroupSchema } from 'validators';

export default function CreateSubgroup({ isOpen, onClose, groupId }) {
	const initialRef = useRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(createSubgroupSchema),
	});
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const createSubgroup = (values) => {
		mutate(
			{ key: `groups/${groupId}/subgroups`, method: 'post', data: values },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries(`groups/${groupId}/subgroups`);
					onClose();
				},
			}
		);
	};

	return (
		<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={handleSubmit(createSubgroup)}>
					<ModalHeader>Create New Subgroup</ModalHeader>
					<CloseButton _hover={{bg: "#5D1AB2"}} />
					<ModalBody>
						<FormControl>
							<FormLabel>Subgroup name</FormLabel>
							<InputField {...register('name')}  />
							<ValidationError message={errors.name?.message} />
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button disabled={isLoading} type="submit" _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Create new subgroup'}
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}
