import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Textarea, Text
} from '@chakra-ui/react';

import { InputField, ValidationError, CloseButton, Spinner } from 'components';

//APIs from react-form-hooks for form validations
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

//Schemas
import { createQuoteSchema } from 'validators';

export default function EditGroup({ selected, setSelected, isOpen, onClose }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(createQuoteSchema),
		defaultValues: { content: selected.content },
	});
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const editQuote = (values) => {
		mutate(
			{ key: `quotes/${selected.id}`, method: 'put', data: values },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('quotes');
					setSelected(null);
					onClose();
				},
			}
		);
	};

	const inputStyle = {
		height: 200,
		width: "97.5%",
		background: "#F3F2F0",
		borderRadius: 5,
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton color='#5D1AB2' bg='transparent' border='none' _hover={{ color: '#5D1AB2', border: '1px solid #5D1AB2'}} />
				<form onSubmit={handleSubmit(editQuote)}>
					<ModalBody p={'2em'} mt={'0.5em'}>
						<FormControl>
							<Text color='#000' fontSize='1.2em' fontWeight={700} mb={'1em'}>Edit Quote</Text>
							{/* <FormLabel>Quote content</FormLabel> */}
							<Textarea 
								{...register('content')}
								h={120}
							/>
							<ValidationError message={errors.content?.message} />
						</FormControl>
						<Button m={0} my={'1.2em'} type="submit" _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Save changes'}
						</Button>
					</ModalBody>
				</form>
			</ModalContent>
		</Modal>
	);
}
