import { FormControl, Box, Button, Wrap, WrapItem } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { debounce } from 'helpers';
import { InputField, Spinner } from 'components';
import { useQueryClient } from 'react-query';
import { fetchData } from 'libs/apis';
import { useErrorHandler } from 'helpers';

export default function SearchDropdown({ setSelected, groupSubgroupId, isGroup }) {
	const [query, setQuery] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);

	const client = useQueryClient();

	const { handleError } = useErrorHandler();

	const handleOnChange = debounce((e) => {
		setQuery(e.target.value);
	}, 500);

	useEffect(() => {
		if (query.trim()) {
			setIsLoading(true);
			client
				.fetchQuery(`users/search?searchTerm=${query.trim()}`, fetchData)
				.then((res) => {
					setIsLoading(false);
					setMembers(filterMembers(res.data));
				})
				.catch((e) => {
					setIsLoading(false);
					handleError(e);
				});
		}
		setMembers([]);
		// eslint-disable-next-line
	}, [query]);

	const filterMembers = (members = []) => {
		if (isGroup) {
			return members.filter((member) => !member.owner.groups?.includes(+groupSubgroupId));
		}
		return members.filter((member) => !member.owner.subgroups?.includes(+groupSubgroupId));
	};

	const resultStyle = {
		color: "#fff",
		padding: ".4em",
	}

	return (
		<FormControl>
			<InputField onChange={handleOnChange} placeholder="Search users" />
			<Wrap mt={2} w="100%">
				{isLoading && <Spinner />}
				{members.length
					? members.map((user) => (
							<WrapItem>
								<Button
									onClick={() => setSelected && setSelected(user)}
									key={user.full_name + user.user_id}
									display="block"
									variant="link"
									style={resultStyle}
									bg='grey'
									_hover={{ bg: '#5D1AB2' }}
								>
									{user.full_name}
								</Button>
							</WrapItem>
					  ))
					: null}
				{!isLoading && !members.length && query ? 'No match found' : null}
			</Wrap>
		</FormControl>
	);
}
