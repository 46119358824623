import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Button, useDisclosure, Heading, Image, Icon, Center } from '@chakra-ui/react';
import UpdateGroupDetails from "../modals/UpdateGroupDetails";
import AssignHead from "../modals/AssignHead";
import "../styles/GroupInfo.css";
import { FiEdit3 } from "react-icons/fi";
import { IoCaretBack } from "react-icons/io5";
import { useHistory, useNavigate } from 'react-router-dom';
import { BsCardImage } from "react-icons/bs";

const GroupInfo = ({ name, leader, description, update, groupData, image }) => {
   const history = useHistory();
    
    const [groupInfo, setGroupInfo] = useState(groupData)

    useEffect(()=>{
      if(groupData){
        setGroupInfo(groupData)
      }
    },[groupData])
  
    const [grouphead, setGrouphead] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenAssignHead, onOpen: onOpenAssignHead, onClose: onCloseAssignHead } = useDisclosure();

    const handleChange = (e) => {
        const {name, value} = e.target;

        setGroupInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(groupInfo.description !== "") { 
            update(groupInfo)    
        }
    }
    useEffect(() => {
      if (groupData) {
        setGroupInfo(groupData);
      }
    }, [groupData]);
   
    return (
       <Box className="groupInfo">
         <UpdateGroupDetails 
            isOpen={isOpen}
            onClose={onClose}
            data={groupInfo}
            onChange={handleChange}
            onSubmit={handleSubmit}
         />
          <Flex flexDir="column" gap="19px" w="100%">
            <Flex alignItems="center"  w="100%" justifyContent="space-between" px={0} mb={4}>
               <Flex alignItems="center">
                  <IoCaretBack size="50px" onClick={()=>history.goBack()} cursor="pointer" />
                  <Heading fontSize="2.3em" fontWeight={700} >{name}</Heading>
               </Flex>
               <Button onClick={onOpen} _hover={{bg: "#5D1AB2", color: "#fff", }} w="230px" h="50px" px={4} >
                     <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between" px={2}>
                        <FiEdit3 size="25px" />
                        <Text ml={2}>Update Group Info</Text>
                     </Flex>
               </Button>
            </Flex>
            <Flex alignItems="center" w="100%" justifyContent="space-between" px={0}>
               {image && (
                  <Box w="200px" h="100px" borderRadius="10px" bg="lightgray">
                     <Image src={image} objectFit="cover" w="100%" h="100%" borderRadius="10px" />
                  </Box>
               )}
               {!image && (
                  <Box w="200px" h="100px" borderRadius="10px" bg="lightgrey">
                     <Center w='full' h="full">
                        <Icon as={BsCardImage} boxSize='50px' color='gray' />
                     </Center>
                  </Box>
               )}

               <Box h="full" flex={1} overflow="hidden" pl={4}>{description}</Box>
            </Flex>
             {/* <Box w='100%' >
                <Flex justify="space-between" align="center" w='76.8vw'>
                   <Text fontSize="2.3em" fontWeight={700}>
                      {name}
                   </Text>
                   
                      <Box>
                         <Button
                           border='none'
                           onClick={onOpenAssignHead}
                           // bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                           _hover={{
                              bg: "#5D1AB2",
                              color: "#fff",
                           }}
                         >
                            Assign Leader
                         </Button>
                         <Button 
                           onClick={onOpen} 
                           // bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                           _hover={{
                              bg: "#5D1AB2",
                              color: "#fff",
                           }}
                           w="230px"
                           h="50px"
                           px={4}
                           >
                              <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between" px={2}>
                                 <FiEdit3 size="25px" bg="red" />
                                 <Text ml={2}>Update Group Info</Text>
                              </Flex>
                           </Button>
                      </Box>
                    
                   <UpdateGroupDetails
                      isOpen={isOpen}
                      onClose={onClose}
                      data={groupInfo}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                   />
                   <AssignHead
                      groupData={groupData}
                      isOpen={isOpenAssignHead}
                      onClose={onCloseAssignHead}
                      onSelect={setGrouphead}
                   />
                </Flex>
             </Box>
             <Text fontSize={'.85em'} fontWeight={500} color="#555555" mt={'1em'}>
                {description}
             </Text> */}
          </Flex>
          {/* ...detail.... */}
          {/* <Box className="groupDetails" mt={-4}>
                    <Flex justify="space-between">
                    <Text fontSize="2xl" className="title">{` the  ${name}` }</Text>
                        <Box>
                            <Button onClick={onOpenAssignHead} _hover={{bg: "#5D1AB2"}}>Assign Leader</Button>
                            <Button onClick={onOpen} _hover={{bg: "#5D1AB2"}}>Update</Button>
                        </Box>
                    </Flex>
                    <UpdateGroupDetails 
                        isOpen={isOpen}
                        onClose={onClose}
                        data={groupInfo}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                    <AssignHead
                        groupData={groupData} 
                        isOpen={isOpenAssignHead}
                        onClose={onCloseAssignHead}
                        onSelect={setGrouphead}
                    />
                    <br />
                    <Box className="body">
                        <Text mb={4} fontSize="lg" className="leader">Group Leader: {leader || grouphead}</Text>
                        <Text mb={2}>{description}</Text>
                    </Box>
            </Box> */}

          {/* ......end */}
       </Box>
    );
}
 
export default GroupInfo;
