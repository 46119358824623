import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    Flex,
    Select,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Center,
    Text,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import SearchField from "components/search-field/SearchField";
import React from 'react'
import { CreateSubgroupModal, EditSubgroupModal, DeleteSubgroupModal, TableAction } from 'components';

export default function SubgroupTable({ columns, data, groupId, groupName }) {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
	const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const [selected, setSelected] = useState(null);
    const [groupToDelete, setGroupToDelete] = useState(null);
    
    const history = useHistory();

    const handleOnRowSelected = (row) => {
		history.push(`/groups/${groupId}/subgroups/${row.id}`, { parentGroupName: groupName });
	};

    const editGroup = (group) => () => {
		setSelected(group);
		onOpenEdit();
	};

    const deleteGroup = (group) => () => {
		setGroupToDelete(group);
		onOpenDelete();
	};

    return (
        <Box>
            <CreateSubgroupModal
                groupId={groupId}
                isOpen={isOpen}
                onClose={onClose}
            />
            {selected && (
                <EditSubgroupModal
                    setSelected={setSelected}
                    selected={selected}
                    isOpen={isOpenEdit}
                    onClose={onCloseEdit}
                    groupId={groupId}
                />
            )}
            {groupToDelete && (
                <DeleteSubgroupModal
                    setSelected={setGroupToDelete}
                    selected={groupToDelete}
                    isOpen={isOpenDelete}
                    onClose={onCloseDelete}
                    groupId={groupId}
                />
            )}

            <Flex flexDir="row" justifyContent="space-between" alignItems="center" mb='2em'>
                <SearchField placeholder="Search group" />

                <Box width="20rem">
                    <Box className="flex items-center justify-between">
                        <Select
                            placeholder="All-time"
                            borderColor="#5D1AB2"
                            width="120px"
                            variant="outline"
                            color='#5D1AB2'
                            fontWeight={600}
                            cursor='pointer'
                            _hover={{ border: '1px solid #5D1AB2' }}
                        >
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </Select>

                        <Button
                            mb={4}
                            align="center"
                            width="150px"
                            bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                            _hover={{ bg: "#5D1AB2" }}
                            onClick={onOpen}
                        >
                            Add subgroup
                        </Button>
                    </Box>
                </Box>
            </Flex>
            <Box overflowY={'scroll'} h={'38vh'}>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                {columns?.map(({ key, title }) => (
                                    <Th key={key}>{title}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody fontSize={14}>
                            {data.length ?
                                data?.map((subgroup, index) => (
                                    <Tr key={index} color="#5D1AB2">
                                        <Td>
                                            <Text>{++index}</Text>
                                        </Td>
                                        <Td
                                            cursor='pointer'
                                            fontWeight={700}
                                            onClick={() => handleOnRowSelected(subgroup)}
                                        >
                                            {subgroup.name}
                                        </Td>
                                        <Td>{subgroup.status || 'N/A'}</Td>
                                        <Td>{subgroup.total_members}</Td>
                                        <Td>{subgroup.leader || 'N/A'}</Td>
                                        <Td>{subgroup.summary || 'N/A'}</Td>
                                        <Td>
                                            <Center>
                                                <TableAction
                                                    onRowSelected={() => handleOnRowSelected(subgroup)}
                                                    editGroup={editGroup(subgroup)}
                                                    deleteGroup={deleteGroup(subgroup)}
                                                />
                                            </Center>
                                        </Td>
                                    </Tr>
                                )) : 
                                <Center>
                                    <Text pt={6} fontWeight={700} color='#8975A2'>No subgroups</Text>
                                </Center>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
