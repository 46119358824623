import { useEffect, useState } from 'react';
import { Button, Box, useDisclosure, Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import GroupInfo from './components/GroupInfo';
import { useInfiniteData, useData } from 'data';
import { Spinner, Table, CreateSubgroupModal, EditSubgroupModal, DeleteSubgroupModal, TableAction } from 'components';

import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';

// const tableColumns = [
// 	{
// 		key: 'serialNumber',
// 		title: 'S/N',
// 	},
// 	{
// 		key: 'name',
// 		title: 'Name',
// 	},
// 	{
// 		key: 'total_members',
// 		title: 'Members',
// 	},
// 	{
// 		key: 'action',
// 		title: 'Action',
// 	},
// ];

const subtableColumns = [
	{
		key: 'serialNumber',
		title: 'S/N',
	},
	{
		key: 'name',
		title: 'Name',
	},
	{
		key: 'action',
		title: 'Action',
	},
];

export default function SubgroupTable({ groupId, parentName }) {
	const { data: groupData } = useData(`groups/list-all`)
	const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteData(
		`groups/${groupId}/subgroups`,
		5
	);

	const { enqueueSnackbar } = useSnackbar();
	const { mutate } = useMutation(mutateFunction);

	const [currentGroup, setCurrentGroup] = useState([]);
	const [subgroups, setSubgroups] = useState([]);
	const [selected, setSelected] = useState(null);
	const [groupToDelete, setGroupToDelete] = useState(null);

	const history = useHistory();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
	const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

	useEffect(() => {
		if (data && data.pages) {
			const pages = data.pages;
			let allSubgroups = [];
			pages.forEach((page) => {
				allSubgroups = [...allSubgroups, ...page.items];
			});
			setSubgroups(allSubgroups);
		}
	}, [data]);

	useEffect(() => {

		if(groupData) {
			const findById = (arr, id) => {
				return arr.filter(val => val.id === parseInt(id))
			}
	
			const currGroup = findById(groupData.data, groupId)
	
			setCurrentGroup(currGroup)
		}

	}, [groupData, groupId])

	// Update group details
	const updateGroupInfo = (value) => {
		mutate(
			{key: `groups/${groupId}`, method: "put", data: value},
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					history.go(0)
				}
			}
		)
	}

	const editGroup = (group) => () => {
		setSelected(group);
		onOpenEdit();
	};
	const deleteGroup = (group) => () => {
		setGroupToDelete(group);
		onOpenDelete();
	};

	const handleOnRowSelected = (row) => {
		history.push(`/groups/${groupId}/subgroups/${row.id}`, { parentGroupName: parentName });
	};

	const mapGroups = (groups = []) => {
		return groups.map((group, i) => ({
			...group,
			serialNumber: ++i,
			action: <TableAction editGroup={editGroup(group)} deleteGroup={deleteGroup(group)} />,
		}));
	};

	if (isLoading) return <Spinner />;

	return (
      <Box pt={8} >
         {groupData && (
            <GroupInfo
               name={parentName}
               leader={currentGroup[0]?.members[0]?.profile?.full_name}
               description={currentGroup[0]?.description}
               update={updateGroupInfo}
               groupData={currentGroup[0]}
			   image={currentGroup[0]?.picture}
			//    onClose={}
            />
         )}

        
         {/* <Flex flexDir="row" justifyContent="space-between" >
            <Text fontWeight={600}>Subgroups</Text>
            <Button onClick={onOpen} _hover={{ bg: "#5D1AB2" }}>
               Add subgroup
            </Button>
         </Flex>
         <CreateSubgroupModal
            groupId={groupId}
            isOpen={isOpen}
            onClose={onClose}
         />
         {selected && (
            <EditSubgroupModal
               setSelected={setSelected}
               selected={selected}
               isOpen={isOpenEdit}
               onClose={onCloseEdit}
               groupId={groupId}
            />
         )}
         {groupToDelete && (
            <DeleteSubgroupModal
               setSelected={setGroupToDelete}
               selected={groupToDelete}
               isOpen={isOpenDelete}
               onClose={onCloseDelete}
               groupId={groupId}
            />
         )}
         {subgroups?.length ? (
            <Box mt={8}>
               <Table
                  onRowSelected={handleOnRowSelected}
                  columns={subtableColumns}
                  data={mapGroups(subgroups)}
               />
            </Box>
         ) : (
            <Box>
               <Text>No subgroup added yet</Text>
            </Box>
         )}
         {isFetchingNextPage ? <Spinner /> : null}
         <Box mb={20} mt={4} display="flex" justifyContent="center">
            {hasNextPage ? (
               <Button
                  disabled={isFetchingNextPage}
                  onClick={fetchNextPage}
                  variant="outline"
               >
                  Load more
               </Button>
            ) : null}
         </Box> */}


      </Box>
   );
}
