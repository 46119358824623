import { Input } from '@chakra-ui/react';
import { useAppContext } from 'store/AppContext';
import { forwardRef } from 'react';

const InputField = forwardRef((props, ref) => {
	const {
		state: { theme: brandColors },
	} = useAppContext();

	return (
		<Input
			ref={ref}
			_focus={{ borderColor: brandColors.primaryColor, boxShadow: `0 0 0 1px ${brandColors.primaryColor}` }}
			{...props}
		/>
	);
});

export default InputField;
