import { Box, Icon } from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
   Menu,
   MenuButton,
   MenuList,
   MenuItem,
   MenuDivider,
   Select,
} from "@chakra-ui/react";
import AssignRole from "./submenu/AssignRole";
import { useMutation, mutateFunction } from 'libs/apis'
import { useSnackbar } from 'notistack'
import client from "libs/client";

export default function TableAction({ member, removeMember, toggleFullMember }) {
   const listStyle = {
      padding: 4,
      width: "150px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
   };

   const itemStyle = {
      color: "#5D1AB2",
      border: "none",
      padding: 2,
      paddingLeft: 8,
      background: "#fff",
      float: "right",
      textAlign: "right",
      "&:hover": {
         background: "#eee",
      },
   };

   const history = useHistory()

   // const toggleFullMember = () => history.push(`/memberProfile/${member?.user_id}`);

   const { mutate, isLoading } = useMutation(mutateFunction)
	const { enqueueSnackbar } = useSnackbar();

   const updateRole = (role) => {
      const value = { groupRole: role }
      mutate(
         { key: `groups/${member.group_id}/members/${member.user_id}/update-role`, method: 'put', data: value },
         {
            onSuccess(res) {
               enqueueSnackbar(res.message, { variant: 'success'});
            }
         }
      )
   }

   return (
      <Box>
         <Menu closeOnSelect={false}>
            <MenuButton
               style={{
                  background: "transparent",
                  border: "none",
                  color: "#000",
               }}
            >
               <Icon as={BiDotsHorizontalRounded} w={6} h={6} />
            </MenuButton>
            <MenuList
               style={listStyle}
               boxShadow="0px 20px 40px rgba(93, 26, 178, 0.15)"
            >
               <MenuItem
                  _focus={{ outline: "none" }}
                  _hover={{ bg: "gray.400" }}
                  style={itemStyle}
                  cursor='pointer'
               >
                  <AssignRole updateRole={updateRole} />
               </MenuItem>
               <MenuDivider />
               <MenuItem 
                  _focus={{ outline: "none" }} 
                  style={itemStyle} 
                  onClick={() => toggleFullMember(`/memberProfile/${member?.user_id}`)}
               >
                  View Profile
               </MenuItem>
               <MenuDivider />
               <MenuItem
                  _focus={{ outline: "none" }}
                  style={itemStyle}
                  border="none"
                  onClick={() => removeMember(member)}
               >
                  Remove
               </MenuItem>
            </MenuList>
         </Menu>
      </Box>
   );
}
