export const selected = {
    border: '1px solid #5D1AB2',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5em 1em',
    borderRadius: '6px',
    color: '#5D1AB2',
    fontWeight: 600,
    background: 'transparent',
    marginRight: 10,
    marginBottom: 10,
}