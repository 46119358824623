import { useSnackbar } from 'notistack';
import { hexToRgb } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import { useState, useEffect } from 'react';

export const colorOpacity = (hexColor, opacity = 0.5) => {
	const digits = hexToRgb(hexColor).match(/\d+/g);
	return `rgba(${digits[0]}, ${digits[1]}, ${+digits[2] - 50}, ${opacity})`;
};
export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
		// eslint-disable-next-line
	}, [value]);

	return debouncedValue;
};
export const debounce = (fn, delay) => {
	const timers = [];
	return function delayedFn(...args) {
		const timer = setTimeout(() => {
			fn(...args);
		}, delay);
		if (timers.length > 0) {
			clearTimeout(timers.pop());
		}
		timers.push(timer);
	};
};

export const twentyFourHoursTo12 = (time) => {
	return moment(time, ['HH:mm']).format('hh:mmA');
};
export const twelfHoursTo24 = (time) => {
	return moment(time, ['hh:mm A']).format('HH:mm');
};
export const dateTimeToDate = (date, time12) => {
	const time24 = twelfHoursTo24(time12);
	date = getDate(date);
	return moment(`${date} ${time24}`).toDate();
};
export const getDate = (date = '') => {
	const dateArr = date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)[0].split('-');
	const year = dateArr[0];
	const month = dateArr[1];
	let day = +dateArr[2] + 1;
	day = String(day).length < 2 ? `0${day}` : day;
	return `${year}-${month}-${day}`;
};

export const useErrorHandler = () => {
	const { enqueueSnackbar } = useSnackbar();

	const getErrorMessage = (error) => {
		if (error.response && error.response.data) {
			const { message, error: errorMessage } = error.response.data;
			if (message) {
				return Array.isArray(message) ? message.join(',') : message;
			}
			if (errorMessage) {
				return Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage;
			}
			return error.response.data;
		}
		if (error.response) {
			return error.response.statusText || 'Error occurred';
		}
		return error.message;
	};

	const handleError = (error) => {
		// console.log('error', getErrorMessage(error));
		if (!error.response) {
			return enqueueSnackbar(error.message, { variant: 'error', preventDuplicate: true });
		}
		enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
	};

	return { handleError };
};
