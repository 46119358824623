import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Box, Text, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { Spinner } from 'components';

//APIs
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

export default function DeleteQuote({ selected, setSelected, isOpen, onClose }) {
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const deleteQuote = () => {
		mutate(
			{ key: `quotes/${selected.id}`, method: 'delete' },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('quotes');
					setSelected(null);
					onClose();
				},
			}
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<Flex mt={'2em'} direction="column" align="center">
						<WarningIcon boxSize={8} color="yellow.400" />
						<Text mt={4} textAlign="center" fontSize={'.9em'}>
							{selected.is_today_quote
								? 'You can not delete currently displayed quote of the day'
								: 'Are you sure you want to delete this quote?'}
						</Text>
					</Flex>
					<Flex justify={'center'} my={'1.5em'} gap={"1em"}>
						{!selected.is_today_quote ? (
							<Button type="submit" fontSize={'.7em'} onClick={deleteQuote} bg="#E8342C" fontWeight={700} _hover={{ bg: 'red' }}>
								{isLoading ? <Spinner /> : 'Delete'}
							</Button>
						) : null}
						<Button bg="transparent" color='#5D1AB2' border='2px solid #5D1AB2' mr={3} fontSize={'.7em'} fontWeight={700} onClick={onClose}>
							Cancel
						</Button>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
