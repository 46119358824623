import { SET_PRIMARY_COLOR, SET_SECONDARY_COLOR, SET_AUTH_USER, SET_UI_THEME, USER_SUBSCRIPTION, SYSTEM_NOTIFICATIONS } from './actionTypes';

const reduceTheme = (state = {}, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PRIMARY_COLOR:
			return { ...state, primaryColor: payload.color };
		case SET_SECONDARY_COLOR:
			return { ...state, secondaryColor: payload.color };
		default:
			return state;
	}
};
const storeReducer = (state = {}, action) => {
	const { type } = action;

	switch (type) {
		case SET_PRIMARY_COLOR:
			return { ...state, theme: reduceTheme(state.theme, action) };
		case SET_SECONDARY_COLOR:
			return { ...state, theme: reduceTheme(state.theme, action) };
		case SET_AUTH_USER:
			return { ...state, authUser: action.payload.authUser };
		case USER_SUBSCRIPTION:
			return { ...state, authSub: action.payload.authSub };
		case SYSTEM_NOTIFICATIONS:
			return { ...state, systemNotifications: action.payload.systemNotifications };
		case SET_UI_THEME:
			return { ...state, theme: { ...action.payload } };
		default:
			return state;
	}
};

export default storeReducer;
