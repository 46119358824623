import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Box, Text, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { Spinner } from 'components';

//APIs
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

export default function RemoveMember({ selected, setSelected, groupName, isOpen, onClose, requestKey, invalidateKey }) {
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	// const removeMember = () => {
	// 	mutate(
	// 		{ key: `${requestKey}`, method: 'put', data: { memberId: selected.id } },
	// 		{
	// 			onSuccess(res) {
	// 				enqueueSnackbar(res.message, { variant: 'success' });
	// 				client.invalidateQueries(`${invalidateKey}`);
	// 				setSelected(null);
	// 				onClose();
	// 			},
	// 		}
	// 	);
	// };

	const removeMember = () => {
		mutate(
			{ key: `groups/${selected.group_id}/remove/${selected.user_id}`, method: 'put' },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries(`${invalidateKey}`);
					setSelected(null);
					onClose();
				},
			}
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<Flex mt={8} mb={5} flexDir="column" alignItems="center">
						<WarningIcon boxSize={8} color="yellow.300" />
						<Text mt={4} textAlign="center">
							Are you sure you want to remove <strong>{selected?.profile?.full_name}</strong> from{' '}
							<strong>{groupName}</strong>?
						</Text>
					</Flex>
					<Flex justify={'center'} my={'1.5em'}>
						<Button type="submit" fontSize={'.7em'} onClick={removeMember} bg="#E8342C" fontWeight={700} _hover={{ bg: 'red' }}>
							{isLoading ? <Spinner /> : 'Remove Member'}
						</Button>
						<Button bg="transparent" color='#5D1AB2' border='2px solid #5D1AB2' mr={3} fontSize={'.7em'} fontWeight={700} onClick={onClose}>
							Cancel
						</Button>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
