import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button, Box, Text, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { Loading } from 'components';

//APIs
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

export default function DeleteGroup({ selected, setSelected, isOpen, onClose }) {
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const deleteGroup = () => {
		mutate(
			{ key: `groups/${selected.id}`, method: 'delete' },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('groups');
					setSelected(null);
					onClose();
				},
			}
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton color='#5D1AB2' bg='transparent' border='none' _hover={{ color: '#5D1AB2', border: '1px solid #5D1AB2'}} />
				<ModalBody>
					<Flex mt={'2em'} direction="column" align="center">
						<WarningIcon boxSize={8} color="yellow.400" />
						<Text mt={4} textAlign="center" fontSize='.9em' w='90%'>
							Are you sure you want to delete this group? <br />
							All subgroups under this group would also be deleted
						</Text>
					</Flex>
					<Flex gap={"1rem"} justify={'center'} my={'1.5em'}>
						<Button type="submit" fontSize={'.7em'} onClick={deleteGroup} bg="#E8342C" fontWeight={700} _hover={{ bg: 'red' }}>{isLoading ? <Loading /> : 'Delete'}</Button>
						<Button bg="transparent" color='#5D1AB2' border='2px solid #5D1AB2' mr={3} fontSize={'.7em'} fontWeight={700} onClick={onClose}>
							Cancel
						</Button>
            		</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
