import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Box, Text, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { Spinner } from 'components';

//APIs
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

export default function DeleteSubgroup({ selected, setSelected, isOpen, onClose, groupId }) {
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const deleteSubgroup = () => {
		mutate(
			{ key: `groups/${groupId}/subgroups/${selected.id}`, method: 'delete' },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries(`groups/${groupId}/subgroups`);
					setSelected(null);
					onClose();
				},
			}
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<Flex mt={8} mb={5} flexDir="column" alignItems="center">
						<WarningIcon fontSize={50} color="yellow.300" />
						<Text mt={4} textAlign="center">
							Are you sure you want to delete this subgroup, <strong>{selected.name}</strong>?
						</Text>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Box mb={4} display="flex" w="100%" justifyContent="center">
						<Button onClick={deleteSubgroup} w="110px" mr="10px" disabled={isLoading} _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Delete group'}
						</Button>
						<Button w="110px" variant="outline" onClick={onClose} color="#5D1AB2" bg="#eee">
							Cancel
						</Button>
					</Box>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
