import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalCloseButton,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Text,
	Textarea
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { InputField, ValidationError, CloseButton, Spinner } from 'components';

//APIs from react-form-hooks for form validations
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

//Schemas
import { createQuoteSchema } from 'validators';

export default function CreateQuote({ isOpen, onClose }) {
	const [content, setContent] = useState();
	const initialRef = useRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(createQuoteSchema),
	});
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const createQuote = () => {
		if(!content){
			enqueueSnackbar('Please add a quote')
			return
		}
		const formData = new FormData();
        formData.append('content', content);
		mutate(
			{ key: 'quotes', method: 'post', data: formData },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('quotes');
					onClose();
				},
			}
		);
	};

	const inputStyle = {
		height: 200,
		width: "97.5%",
		background: "#F3F2F0",
		borderRadius: 5,
	}

	return (
		<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton color='#5D1AB2' bg='transparent' border='none' _hover={{ color: '#5D1AB2', border: '1px solid #5D1AB2'}} />
				{/* <form onSubmit={handleSubmit(createQuote)}> */}
				{/* <form onSubmit={createQuote}> */}
					<ModalBody p={'2em'} mt={'0.5em'}>
						<FormControl>
							<Text color='#000' fontSize='1.2em' fontWeight={700} mb={'1em'}>New Quote</Text>
							{/* <FormLabel>Quote content</FormLabel> */}
							<Textarea 
								onChange={(e) => setContent(e.target.value)}
								// {...register('content')} 
								name='content'
								placeholder="Type your quote here..." 
								ref={initialRef} 
								h={120}
							/>
						</FormControl>
							<ValidationError message={errors.content?.message} />
						<Button onClick={()=> createQuote()} m={0} my={'1.2em'} type="submit" _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Add quote'}
						</Button>
					</ModalBody>
				{/* </form> */}
			</ModalContent>
		</Modal>
	);
}
