import GroupsAction from "../menu/GroupsAction";

import {
   Avatar,
   Box,
   Button,
   Flex,
   Table,
   Thead,
   Tbody,
   Center,
   Tr,
   Th,
   Td,
   TableContainer,
   Text,
} from "@chakra-ui/react";
import SearchField from "components/search-field/SearchField";
import { useHistory } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { useEffect, useState } from "react";
import moment from "moment";
import SortByColumn from "utils/SortByColumn";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";





const GroupMember = ({ columns = [], statsData = [], removeMember, onOpen, group }) => {
   const [downloadCSVLoading, setDownloadCSVLoading] = useState(false);
   const [data, setData] = useState(statsData);
    const [sortedColumn, setSortedColumn] = useState("");
    const [asc, setAsc] = useState(false);
    const sortByColumn = (column) => {
        SortByColumn(column, data, setData, asc, setAsc, sortedColumn, setSortedColumn);
    }
    useEffect(() => {
        if(statsData.length) {
            setData(statsData);
            // sortByColumn(sortedColumn);
        }
    },[statsData])
   const [searchTerm, setSearchTerm] = useState('');
   const [dataToDisplay, setDataToDisplay] = useState(data);

useEffect(() => {
  if (searchTerm.trim() === '') {
    return setDataToDisplay(data);
  } else {
    const filteredData = data.filter((item) =>
      item?.member?.profile?.full_name?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
    return setDataToDisplay(filteredData);
  }
}, [data, searchTerm]);

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
};

   function fetchAndDownloadCSV() {
      setDownloadCSVLoading(true);
         
         const csvData = convertToCSV(statsData);
         
         // Create a Blob object with the CSV data
         const blob = new Blob([csvData], { type: 'text/csv' });
         
         // Create a download link for the CSV file
         const link = document.createElement('a');
         link.href = URL.createObjectURL(blob);
         link.download = 'group_member_data';
         
         // Trigger the download
         link.click();
         setDownloadCSVLoading(false);
      }
      
      function convertToCSV(data) {
         const separator = ','; // You can change this to another separator if needed
         const selectedFields = ['id', 'user_id', 'group_role', 'email', 'mobile_phone', 'position', 'last_seen'];
         const csvHeader = selectedFields.join(separator);
         const csvRows = data.map((row) => {
           const rowData = selectedFields.map((field) => row[field]);
           return rowData.join(separator);
         });
       
         return csvHeader + '\n' + csvRows.join('\n');
       }

   const history = useHistory()

   const toggleFullMember = (path) => {
      history.push(path)
   }

   return (
      <div>
         <Flex flexDir="row" justifyContent="space-between" alignItems="center" mb='2em'>
            <SearchField placeholder="Search group" onChange={(e)=> handleSearch(e)} />

            <Box width="20rem">
               <div className="flex items-center justify-between  ">
                  < Button
                     mb={4}
                     isLoading={downloadCSVLoading}
                     align="center"
                     width="150px"
                     bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                     _hover={{ bg: '#5D1AB2' }}
                      onClick={()=>fetchAndDownloadCSV()}
                     leftIcon= {<FiDownload />}
                  >
                     Export CSV
                  </Button>

                  <Button
                     mb={4}
                     align="center"
                     width="150px"
                     bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                     _hover={{ bg: '#5D1AB2' }}
                     onClick={onOpen}
                  >
                     Add members
                  </Button>
               </div>
            </Box>
         </Flex>
         <Box overflowY={'scroll'} h={'38vh'}>
            <TableContainer>
               <Table variant="simple">
                  <Thead>
                     <Tr>
                        {columns?.map(({ key, title }) => (
                           // <Th key={key}>{title}</Th>
                           <Th key={key}
                                    onClick={() => sortByColumn(key)}
                                    cursor="pointer"
                                >
                                    <Flex alignItems="center">
                                        <Text>{title}</Text>
                                        {asc && sortedColumn === key && (
                                        <TriangleDownIcon ml={2} />
                                        )}
                                        {(!asc && sortedColumn === key) && (
                                        <TriangleUpIcon ml={2} />
                                        )}
                                    </Flex>
                                </Th>
                        ))}
                     </Tr>
                  </Thead>
                  <Tbody fontSize={14}>
                     {dataToDisplay.length ? dataToDisplay?.map((member, i) => (
                        <Tr key={i} color="#5D1AB2">
                           <Td>
                              <Box>
                                 <Text>{++i}</Text>
                              </Box>
                           </Td>
                           <Td
                              cursor={'pointer'}
                              onClick={() => toggleFullMember(`/memberProfile/${member?.user_id}`)}
                           >
                              <Box>
                                 <Flex alignItems="center" gap="3px">
                                    <Avatar src={member.img} name={member.name} size='sm' />
                                    <Text ml="8px" fontWeight={700}>{member.name}</Text>
                                 </Flex>
                              </Box>
                           </Td>
                           <Td>{member.email}</Td>
                           <Td>{member.mobile_phone}</Td>
                           <Td textTransform='capitalize'>{member.group_role}</Td>
                           <Td>{ moment(member?.last_seen).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}</Td>
                           <Td>
                              <Center>
                                 <GroupsAction 
                                    member={member} 
                                    removeMember={removeMember} 
                                    toggleFullMember={toggleFullMember} 
                                 />
                              </Center>
                           </Td>
                        </Tr>
                     )): 
                        <Center>
                           <Text pt={6} fontWeight={700} color='#8975A2'>No members</Text>
                        </Center>
                     }
                  </Tbody>
               </Table>
            </TableContainer>
         </Box>
      </div>
   );
};

export default GroupMember;
