import * as yup from 'yup';
const errorMessage = (fieldName) => `${fieldName} is required`;

export const loginSchema = yup.object().shape({
	email: yup.string().email().trim().required().label('Email'),
	password: yup.string().trim().required().label('Password'),
});

export const createChruchSchema = yup.object().shape({
	name: yup.string().required(),
	email: yup.string().required(),
	address: yup.string().required(),
});

export const createSubscriptionSchema = yup.object().shape({
	name: yup.string().required(),
	amount: yup.number().required(),
	membersCount: yup.string().required(),
	maxMembers: yup.number().required(),
	addChurch: yup.string(),
	showSubscription: yup.boolean()
});
export const paymentSchema = yup.object({
	cardNo:yup.string().trim().required(errorMessage("Card No")),
	expiryDate:yup.string().trim().required(errorMessage("Expiry Month")),
	year:yup.string().trim().required(errorMessage("Year")),
	cvc:yup.string().trim().required(errorMessage("CVC")),
	zipCode:yup.string().trim().required(errorMessage("zip code"))
  });


export const createChruchSchema2 = yup.object().shape({
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	email: yup.string().required(),
	phoneNumber: yup.string().required(),
	password: yup.string().required(),
	comfirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref('password'), null]),
	// membership: yup
	// .object({
	// 	value: yup.string().trim().required(),
	// })
	// .required()
	// .nullable(),
	// agreedToGiftAidTerms: yup.boolean().notRequired(),
});

export const createGroupSchema = yup.object().shape({
	name: yup.string().required(), 
	description: yup.string().required(),
	picture: yup.mixed().required() 
});

export const addMinisterSchema = yup.object().shape({
	officiatingPastor: yup.string().optional(), 
});

export const createQuoteSchema = yup.object().shape({
	content: yup.string().required(),
});

export const createSubgroupSchema = yup.object().shape({
	name: yup.string().required(),
});

export const createNewGroupMember = yup.object().shape({
	memberId: yup.number().required(),
});

export const editSubgroupSchema = yup.object().shape({
	name: yup.string().required(),
});

export const editGroupSchema = yup.object().shape({
	name: yup.string().required(),
});

export const messageSchema = yup.object().shape({
	content: yup.string().required().trim(),
	receivers: yup
	.object({
		value: yup.array().required(),
	})
	.required()
	.nullable(),
});



// event
export const createEventGeneralSchema = yup.object().shape({
	title: yup.string().required(),
	startDate: yup.string().required().nullable(),
	endDate: yup.string().required().nullable(),
	startTime: yup.string().required(),
	endTime: yup.string().required(),
	description: yup.string().required(),
	checkIn: yup.array(),
});

export const createEventTemplateGeneralSchema = yup.object().shape({
	title: yup.string().required(),
	// day: yup.string().required(),
	time: yup.string().required(),
	location: yup.string().required(),
	description: yup.string().required(),
});

export const createEventDescriptionSchema = yup.object().shape({
	description: yup.string().required(),
});
export const createEventCheckInSchema = yup.object().shape({
	// checkIn: yup.array().min(1).max(6).required(),
});

export const resetPasswordSchema = yup.object().shape({
	email: yup.string().email().trim().required(),
});

export const comfirmPasswordSchema = yup.object().shape({
	otp: yup.string().required(),
	newPassword: yup.string().required(),
	comfirmNewPassword: yup
		.string()
		.required()
		.oneOf([yup.ref('newPassword'), null]),
});

export const newChildSchema = yup.object().shape({
	firstname: yup.string('Please input a valid first name').required('Please input a valid first name'),
	lastname: yup.string('Please input a valid last name').required('Please input a valid last name'),
	fullname: yup.string().required('Please input a valid first and last name'),
	dob: yup.date().required(),
	role: yup.string(`Please add guardian's role`).test("guardian_validation", (value, textContext) => {
		return value.toLowerCase() === 'mother' || value.toLowerCase() === 'father' ? true : textContext.createError({message: 'Guardian must be a mother or a father'})
	}).required(`Please add guardian's role`)
});

export const newMemberSchema = yup.object().shape({
	email: yup.string().email().trim().required(),
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	phoneNumber: yup.string().required(),
});

export const newSuperAdminSchema = yup.object().shape({
	email: yup.string().email().trim().required(),
	firstName: yup.string().optional(),
	lastName: yup.string().optional(),
	phoneNumber: yup.string().optional(),
});

export const editMemberSchema = yup.object().shape({
	firstName: yup.string().required('Please enter a first name'),
	lastName: yup.string().required('Please enter a last name'),
	phoneNumber: yup.string().required('Please enter a phone number').typeError('Please enter a phone number'),
	status: yup.string().optional().typeError('Please fill your status'),
	maritalStatus: yup.string().required('Please choose a marital status').typeError('Please choose a marital status'),
	profession: yup.string().required().typeError('Please enter a profession'),
	address: yup.string().optional('Please enter an address').typeError('Please enter an address'),
	gender: yup.string().required('Please choose a gender').typeError('Please choose a gender'),
	dob: yup.string().required('Please select the date of birth').typeError('Please select the date of birth'),
	city: yup.string().optional('Please pick a city').typeError('Please pick a city'),
	country: yup.string().required('Please pick a country').typeError('Please pick a country'),
});

export const churchPos = yup.object().shape({
	churchPosition: yup.string()
})


// church signup
export const createChurchAdminSchema = yup.object().shape({
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	email: yup.string().required(),
	password: yup.string().required(),
	phoneNumber: yup.string().required(),
})

export const createChurchDetailsSchema = yup.object().shape({
	churchName: yup.string().required(),
	websiteUrl: yup.string().required(),
	address: yup.string().required(),
})

export const createWeeklyEventSchema = yup.object().shape({
	name: yup.string().required(),
	day: yup.string().required(),
	time: yup.string().required(),
	location: yup.string().required(),
	description: yup.string().required(),
  });

  export const createEmailSchema = yup.object().shape({
	title: yup.string().required(),
	startDate: yup.string().required().nullable(),
	startTime: yup.string().required(),
	minAge: yup.number().optional(),
	maxAge: yup.number().optional(),
	gender: yup.string().optional()
});

export const createCard = yup.object().shape({
	title: yup.string().required(),
	subtitle: yup.string().required(),
});