import React, {useMemo} from 'react';
import { Table as CTable, TableCaption, Thead, Tr, Th, Tbody, Td, chakra } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from "react-table";  
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
/**
 * columns =[{
 *  key: 'firstName',
 *  title: 'First Name'
 * }]
 */

const customStyle = {
	textTransform: "capitalize",
	color: "#000",
}

const dailyStyle = {
	textTransform: "capitalize",
	color: "#5D1AB2",
	fontWeight: 700,
	transform: "scale(1.01)",
	border: "1px solid #eee",
}

export default function Table({ title, data = [], columns = [], onRowSelected, trStyle = {}, ...rest }) {

	const tableHeaders = columns.map(item => ({accessor: item.key, Header: item.title}))
	const _columns = useMemo(
		() => tableHeaders,
		[]
	);

	const tableData = useMemo(
		() =>
	   data.map((item) => ({
			...item,
			...(item?.profile || {}),
		  })),
		[data?.length]
	  );
	
	const { getTableProps, getTableBodyProps, headerGroups, rows } = useTable(
		{ columns : _columns, data: tableData },
		useSortBy
	);
	
	const tableRow = rows.map((row) => row.original);
	const tableHead2 = (
		<Thead>
			<Tr>
				{columns.map((col, i) => (
					<Th key={i}>{col.title}</Th>
				))}
			</Tr>
		</Thead>
	);

	const tableHead = (
		<Thead cursor="pointer" borderRadius="5px">
            {headerGroups.map((headerGroup) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                h="4rem"
                fontWeight="light"
                overflowX="auto"
                color="#8975A2"
                padding="1rem 0.5rem"
              >
                {headerGroup.headers.map((column) => (
                  <Th
                  className="cursor-pointer"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                  >
   
                    {column.render("Header")}
                    <chakra.span pl="1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
	)
	const tableBody = (
		<Tbody {...getTableBodyProps()}>
			{tableRow.map((value, index) => (
				
				<Tr
					{...trStyle}
					key={value.id}
					style={value.is_today_quote ? dailyStyle : customStyle}
					fontSize={14}
				>
					{columns.map((col, i) => (
						<Td key={i}>				
							{col.key == "s/n" ? index + 1 : value[col.key]?.length > 125 ? `${value[col.key].slice(0, 80)}...` : value[col.key]}</Td>
					))}
				</Tr>
			))}
		</Tbody>
	);

	return (
		<CTable {...getTableProps()} {...rest}>
			{title && <TableCaption placement="top">{title}</TableCaption>}
			{tableHead}
			{tableBody}
		</CTable>
	);
}

Table.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
		})
	),
	trStyle: PropTypes.object,
};
