import ClipLoader from 'react-spinners/ClipLoader';
import { useAppContext } from 'store/AppContext';

export default function Loading({ size = 30 }) {
	const {
		state: { theme },
	} = useAppContext();

	return <ClipLoader color={theme?.primaryColor} loading={true} size={size} />;
}
