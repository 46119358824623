import {
    Box,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Text
} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { AiFillCaretDown } from 'react-icons/ai'

export default function AssignRole({ updateRole }) {

    return (
        <Menu>
            <MenuButton
                as={Button}
                rightIcon={<Icon as={AiFillCaretDown} />}
                m={0}
                ml={-6}
                bg='transparent'
                color='inherit'
                border='none'
                fontWeight={700}
                fontSize={'1em'}
                _hover={{ background: 'transparent'}}
                _focus={{ outline: "none", background: 'transparent' }}
                _active={{ background: 'transparent'}}
            >
                Assign Role
            </MenuButton>
            <MenuList w={100} pl={6} bg='#fff' h={120} border='none'>
                <MenuOptionGroup type='radio' bg='#fff'>
                    <MenuItemOption value='leader' bg='inherit' _focus={{ outline: "none" }} onClick={(e) => updateRole('leader')}>Leader</MenuItemOption>
                    <MenuItemOption value='member' bg='inherit' _focus={{ outline: "none" }} onClick={(e) => updateRole('member')}>Member</MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
            </MenuList>
        </Menu>
    )
}
