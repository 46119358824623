import { createContext, useContext, useReducer } from "react";
import appReducer from "./reducer";
import { getUITheme, getAuthUser, getSystemNotifications } from "libs/auth";

export const appContext = createContext(null);

export const useAppContext = () => useContext(appContext);

const initialState = {
   theme: {
      primaryColor: getUITheme()?.primaryColor || "#5D1AB2",
      secondaryColor: getUITheme()?.secondaryColor || "#FFB600",
   },
   authUser: getAuthUser(),
   systemNotifications: getSystemNotifications(),
};

export const AppContextWrapper = ({ children }) => {
   const [state, dispatch] = useReducer(appReducer, initialState);

   return (
      <appContext.Provider value={{ state, dispatch }}>
         {children}
      </appContext.Provider>
   );
};

export const withAppContext =
   (Component) =>
   ({ ...props }) =>
      (
         <AppContextWrapper>
            <Component {...props} />
         </AppContextWrapper>
      );
