import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
// Client Provider
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
// main styles
import "./index.css";

import { Loading } from "components";

import { AppContextWrapper } from "store/AppContext";
import { ToastProvider } from "react-toast-notifications";

const queryClient = new QueryClient();
const App = lazy(() => import("./App"));

ReactDOM.render(
  <AppContextWrapper>
    <Suspense
      fallback={
        <div className="flex justify-center">
          <Loading size={40} />
        </div>
      }
    >
      <SnackbarProvider
        preventDuplicate={true}
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <QueryClientProvider client={queryClient}>
          <Router>
            <ToastProvider>
              <App />
            </ToastProvider>
          </Router>
        </QueryClientProvider>
      </SnackbarProvider>
    </Suspense>
  </AppContextWrapper>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
