import { useEffect, useState } from "react";
import {
   Button,
   Box,
   useDisclosure,
   Flex,
   Text,
   Select,
   Center,
   Spacer,
   Checkbox,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";

import { useData } from 'data';

import { useInfiniteData } from "data";
import GroupsAction from "./menu/GroupsAction";
import { Spinner, Table, AddMember, RemoveMemberModal } from "components";
import SearchField from "components/search-field/SearchField";
import GroupMember from "./components/GroupMemberTable";
import NewRequestTable from "./components/NewRequestTable";
import SubgroupTable from "./components/SubgroupTable";

const memberColumn = [
   {
      key: "serialNumber",
      title: "S/N",
   },
   {
      key: "name",
      title: "Name",
   },
   {
      key: "email",
      title: "Email",
   },
   {
      key: "mobile_phone",
      title: "Phone no.",
   },
   {
      key: "group_role",
      title: "Position",
   },
   {
      key: "last_seen",
      title: "Last seen",
   },
   {
      key: "action",
      title: "Action",
   }
];

const requestColumn = [
   {
      key: "serialNumber",
      title: "S/N",
   },
   {
      key: "name",
      title: "Name",
   },
   {
      key: "email",
      title: "Email",
   },
   {
      key: "mobile_phone",
      title: "Phone no.",
   },
   {
      key: "address",
      title: "Home Address",
   },
   {
      key: "date",
      title: "Date of request",
   },
   {
      key: "actions",
      title: "Actions",
   }
]

const subgroupColumn = [
   {
      key: "serialNumber",
      title: 'S/N',
   },
   {
      key: "name",
      title: "Name",
   },
   {
      key: "status",
      title: "Status",
   },
   {
      key: "total_members",
      title: "Members",
   },
   {
      key: "leader",
      title: "Subgroup Leader",
   },
   {
      key: "description",
      title: "Description",
   },
   {
      key: "action",
      title: "Actions",
   }
]

export default function MembersTable({
   id,
   requestKey,
   removeRequestKey,
   groupName,
   isGroup,
}) {
   const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
      useInfiniteData(requestKey, 15);
   const { data: request, isLoading: loading } =
      useInfiniteData(`join-requests/${id}/group`, 15);
   const { data: subgroup } = useInfiniteData(`groups/${id}/subgroups`, 15)

   const [members, setMembers] = useState([]);
   const [groupRequest, setGroupRequest] = useState([])
   const [subgroups, setSubgroups] = useState([])

   const [selected, setSelected] = useState(null);

	const [memberTable, setMemberTable] = useState(true);
	const [requestTable, setRequestTable] = useState(false);
	const [subgroupTable, setSubgroupTable] = useState(false);

	const handleMember = () => {
		setMemberTable(true);
		setRequestTable(false);
      setSubgroupTable(false);
	}

	const handleRequest = () => {
      setMemberTable(false);
      setRequestTable(true);
      setSubgroupTable(false);
   };

   const handleSubgroup = () => {
      setMemberTable(false);
      setRequestTable(false);
      setSubgroupTable(true);
   }

   const { isOpen, onOpen, onClose } = useDisclosure();
   const {
      isOpen: isOpenDelete,
      onOpen: onOpenDelete,
      onClose: onCloseDelete,
   } = useDisclosure();

   useEffect(() => {
      if (data && data.pages) {
         const pages = data.pages;
         let allMembers = [];
         pages.forEach((page) => {
            allMembers = [...allMembers, ...page.items];
         });
         setMembers(allMembers);
      }

      if(request && request.pages) {
         const pages = request.pages
         let allGroupRequest = [];
         pages.forEach(page => {
            allGroupRequest = [...allGroupRequest, ...page.items];
         })
         setGroupRequest(allGroupRequest)
      }

      if (subgroup && subgroup.pages) {
			const pages = subgroup.pages;
			let allSubgroups = [];
			pages.forEach((page) => {
				allSubgroups = [...allSubgroups, ...page.items];
			});
			setSubgroups(allSubgroups);
		}

   }, [data, request, subgroup]);

   const removeMember = (member) => {
      setSelected(member);
      onOpenDelete();
   };

   const handleOnRowSelected = (row) => {
      // history.push(`/groups/${groupId}/subgroups/${row.id}`, { parentGroupName: row.name });
   };

   const mapMembers = (members = []) => {
      return members.map((member, i) => ({
         ...member,
         serialNumber: (
            <Box>
               <Checkbox>{++i}</Checkbox>
            </Box>
         ),
         name: member?.member?.profile?.full_name,
         email: member?.member?.email,
         mobile_phone: member?.member?.profile?.mobile_phone,
         position: member?.member?.role,
         last_seen: member?.member?.profile?.last_seen,
         action: (
            // onClick={removeMember(member)}
            <Box variant="ghost" color="black" mt="10px" mb="10px">
               <Center>
                  <GroupsAction />
               </Center>
            </Box>
         ),
      }));
   };

   if (isLoading) return <Spinner />;

   return (
      <Box pt={16} mx='1em'>
         <Box>
            <Flex
               flexDir="row"
               alignItems="center"
               gap="5"
               borderBottom="1px"
               borderColor="#D0D0D0"
               mt="-48px"
               mb="24px"
               cursor='pointer'
               fontWeight={600}
            >
               <Text
                  fontSize={'.95em'}
                  fontWeight={700}
                  px={2}
                  mr={6}
                  mb={-0.8}
                  color={memberTable ? "#5D1AB2" : "#999999"}
                  borderBottom={`2px solid ${memberTable ? `#5D1AB2` : `#A9A9A9`}`}
                  pb="25px"
                  _hover={{ borderColor: "#5D1AB2", color: "#5D1AB2", bg: '#ede5f79b' }}
                  onClick={handleMember}
                  // style={memberTable ? { color: "#5D1AB2" } : {}}
               >
                  Members{members.length ? `(${members.length})` : ''}
               </Text>
               <Text
                  fontSize={'.95em'}
                  fontWeight={700}
                  px={2}
                  mr={6}
                  mb={-0.8}
                  color={requestTable ? "#5D1AB2" : "#999999"}
                  borderBottom={`2px solid ${requestTable ? `#5D1AB2` : `#A9A9A9`}`}
                  pb="25px"
                  _hover={{ borderColor: "#5D1AB2", color: "#5D1AB2", bg: '#ede5f79b' }}
                  onClick={handleRequest}
                  // style={requestTable ? { color: "#5D1AB2" } : {}}
               >
                  New Requests{groupRequest.length ? `(${groupRequest.length})` : ''}
               </Text>
               <Text
                  fontSize={'.95em'}
                  fontWeight={700}
                  px={2}
                  mr={6}
                  mb={-0.8}
                  color={subgroupTable ? "#5D1AB2" : "#999999"}
                  borderBottom={`2px solid ${subgroupTable ? `#5D1AB2` : `#A9A9A9`}`}
                  pb="25px"
                  _hover={{ borderColor: "#5D1AB2", color: "#5D1AB2", bg: '#ede5f79b' }}
                  onClick={handleSubgroup}
                  // style={subgroupTable ? { color: "#5D1AB2" } : {}}
               >
                  Subgroups{subgroups.length ? `(${subgroups.length})` : ''}
               </Text>
            </Flex>
         </Box>

         {/* <Flex flexDir="row" justifyContent="space-between" alignItems="center">
            <SearchField placeholder="Search group" />

            <Box width="20rem">
               <div className="flex items-center justify-between  ">
                  <Select
                     placeholder="Export"
                     borderColor="#5D1AB2"
                     width="120px"
                     variant="outline"
                  >
                     <option value="option1">Option 1</option>
                     <option value="option2">Option 2</option>
                     <option value="option3">Option 3</option>
                  </Select>

                  <Button
                     mb={4}
                     align="center"
                     width="150px"
                     bgGradient="linear(to-t,  #5D1AB2, #8121FC)"
                     _hover={{
                        borderColor: "#8121FC",
                        bg: "#ebedf0",
                        color: "black",
                     }}
                     onClick={onOpen}
                  >
                     Add members
                  </Button>
               </div>
            </Box>
         </Flex> */}

         <AddMember
            isGroup={isGroup}
            requestKey={requestKey}
            id={id}
            isOpen={isOpen}
            onClose={onClose}
         />

         {selected && (
            <RemoveMemberModal
               setSelected={setSelected}
               selected={selected}
               isOpen={isOpenDelete}
               onClose={onCloseDelete}
               requestKey={removeRequestKey}
               invalidateKey={requestKey}
               groupName={groupName}
            />
         )}

         {memberTable &&
            <Box mt={8}>
               {/* <Table
                  onRowSelected={handleOnRowSelected}
                  columns={memberColumn}
                  data={mapMembers(members)}
            /> */}
               <GroupMember
                  columns={memberColumn}
                  statsData={mapMembers(members)}
                  removeMember={removeMember}
                  onOpen={onOpen}
               />
            </Box>
         }

         {/* Member request Table------ */}
         {requestTable &&
            <NewRequestTable
               columns={requestColumn}
               data={mapMembers(groupRequest)}
            />
         }

         {subgroupTable &&
            <SubgroupTable
               columns={subgroupColumn}
               data={subgroups}
               groupId={id}
               groupName={groupName}
            />
         }

         {/*end  Member request Table------ */}

         {isFetchingNextPage ? <Spinner /> : null}
         <Box mb={20} mt={4} display="flex" justifyContent="center">
            {hasNextPage ? (
               <Button
                  disabled={isFetchingNextPage}
                  onClick={fetchNextPage}
                  variant="outline"
               >
                  Load more
               </Button>
            ) : null}
         </Box>
      </Box>
   );
}
