import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	Text,
	IconButton,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useRef, useState, useEffect } from 'react';
import { SearchDropdown, CloseButton, Spinner } from 'components';

//APIs
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { selected } from './commonStyle';

export default function AddMembers({ isOpen, onClose, isGroup, id, requestKey }) {
	const initialRef = useRef();

	const { mutate, isLoading } = useMutation(mutateFunction);

	const [selectedMembers, setSelectedMembers] = useState([]);

	const { enqueueSnackbar } = useSnackbar();

	const client = useQueryClient();

	useEffect(() => {
		if (!isOpen && selectedMembers.length) {
			setSelectedMembers([]);
		}
	}, [isOpen, selectedMembers]);

	const SelectedMember = ({ user, remove, ...rest }) => (
		<Text
			{...rest}
			as="span"
			style={selected}
		>
			{user.full_name}
			<CloseIcon
				ml={'1em'}
				bg='transparent'
				color='#5D1AB2'
				boxSize={2.5}
				border='none'
				onClick={() => remove(user)}
				cursor='pointer'
			/>
		</Text>
	);
	const removeSelectedMember = (user) => {
		setSelectedMembers((members) => {
			const filtered = members.filter((member) => member.user_id !== user.user_id);
			return filtered;
		});
	};

	const updateMembers = (member) => {
		setSelectedMembers((members) => {
			const newMembers = [...members, member];
			return Array.from(new Map(newMembers.map((mem) => [mem.user_id, mem])).values());
		});
	};

	const addMembers = () => {
		const key = isGroup ? `groups/${id}/add-member` : `subgroups/${id}/add-member`;
		mutate(
			{ key, method: 'put', data: { memberIds: selectedMembers.map((member) => member.user_id) } },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries(requestKey);
					onClose();
				},
			}
		);
	};
	
	return (
		<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent p={2}>
				<CloseButton />
				<ModalBody p={'2em'} mt={'0.5em'}>
					<Text color='#000' fontSize='1.2em' fontWeight={700} mb={'1em'}>Add members to {isGroup ? 'group' : 'subgroup'}</Text>							
					<Wrap w="100%">
						{selectedMembers.map((member) => (
							<WrapItem>
								<SelectedMember key={member.user_id} user={member} remove={removeSelectedMember} />
							</WrapItem>
						))}
					</Wrap>
					<SearchDropdown setSelected={updateMembers} groupSubgroupId={id} isGroup={isGroup} />
					<Button bg="grey" m={0} my='1.5em' _hover={{bg: "#5D1AB2"}} onClick={addMembers} disabled={isLoading || !selectedMembers.length}>
						{isLoading ? <Spinner /> : 'Add selected members'}
					</Button>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
