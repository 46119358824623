import * as dotenv from 'dotenv';

dotenv.config();



// export const BASE_URL =   process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.NODE_ENV == "development"? "http://localhost:3900/api/v1" :  process.env.REACT_APP_API_URL;
export const CHURCH_APP_AUTH_USER = 'CHURCH_APP_AUTH_USER';

export const CHURCH_UI_THEME = 'CHURCH_UI_THEME';

export const NUMBER_OF_DATA_PER_PAGE = 10;
