import { useHistory } from "react-router-dom";
import { Box, Icon } from "@chakra-ui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
   Menu,
   MenuButton,
   MenuList,
   MenuItem,
   MenuDivider,
} from "@chakra-ui/react";
import { useMutation, mutateFunction } from 'libs/apis'
import { useSnackbar } from 'notistack'
import { setLocale } from "yup";
import { useQueryClient } from "react-query";

export default function RequestAction({ request, setLocalList, data }) {
   const listStyle = {
      padding: 4,
      width: "150px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
   };

   const itemStyle = {
      color: "#5D1AB2",
      border: "none",
      padding: 4,
      paddingLeft: 8,
      background: "#fff",
      float: "right",
      textAlign: "right",
      "&:hover": {
         background: "#eee",
      },
   };

   const history = useHistory();

   const toggleFullMember = () => history.push(`/memberProfile/${request?.sender?.id}`);

   const { mutate, isLoading } = useMutation(mutateFunction)
	const { enqueueSnackbar } = useSnackbar();
   const client = useQueryClient()

   const acceptRequest = () => {

      const value = {
         "accepted": true,
         "declined": false
      }

      mutate(
         { key: `join-requests/${request.id}/review`, method: 'put', data: value },
         {
            onSuccess(res) {
               enqueueSnackbar('Request accepted', { variant: 'success'});
               setLocalList(data.filter(datum => datum.id !== request.id))
               client.invalidateQueries()
            }
         }
      )
   }

   const ignoreRequest = () => {

      const value = {
         "accepted": false,
         "declined": true
      }

      mutate(
         { key: `join-requests/${request.id}/review`, method: 'put', data: value },
         {
            onSuccess(res) {
               enqueueSnackbar('Request ignored', { variant: 'success'});
               setLocalList(data.filter(datum => datum.id !== request.id))
               client.invalidateQueries()
            }
         }
      )
   }

   return (
      <Box>
         <Menu escape>
            <MenuButton
               style={{
                  background: "transparent",
                  border: "none",
                  color: "#000",
               }}
            >
               <Icon as={BiDotsHorizontalRounded} w={6} h={6} />
            </MenuButton>
            <MenuList
               style={listStyle}
               boxShadow="0px 20px 40px rgba(93, 26, 178, 0.15)"
            >
               <MenuItem
                  _focus={{ outline: "none" }}
                  _hover={{ bg: "gray.400" }}
                  style={itemStyle}
                  onClick={acceptRequest}
               >
                  Accept
               </MenuItem>
               <MenuDivider />
               <MenuItem 
                  _focus={{ outline: "none" }}
                  style={itemStyle}
                  onClick={ignoreRequest}
               >
                  Ignore
               </MenuItem>
               <MenuDivider />
               <MenuItem
                  _focus={{ outline: "none" }}
                  style={itemStyle}
                  border="none"
                  onClick={toggleFullMember}
               >
                  View Profile
               </MenuItem>
            </MenuList>
         </Menu>
      </Box>
   );
}
