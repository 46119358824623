import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAppContext } from 'store/AppContext';

export default function SearchField(props) {
	const {
		state: { theme: brandColors },
	} = useAppContext();
	return (
		<InputGroup w="fit-content">
			<InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
			<Input
				_focus={{ borderColor: brandColors.primaryColor, boxShadow: `0 0 0 1px ${brandColors.primaryColor}` }}
				{...props}
			/>
		</InputGroup>
	);
}
