// disable prettier
import React from "react";
import PropTypes from "prop-types";

export default function FormErrorMessage({ children }) {
  return <span style={{ color: "red", display: "inline-block", marginTop: 5 }}>{children}</span>;
}

FormErrorMessage.defaultProps = {
  children: null
};
FormErrorMessage.propTypes = {
  children: PropTypes.node
};
