import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
    Input,
    Textarea,
    Heading,
    Flex,
    VStack,
    Text,
    Image,
    Center,
    Box,
    Icon
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { CloseButton, Spinner } from 'components';
import { useMutation, mutateFunction } from 'libs/apis';
import { BsCardImage } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';


export default function UpdateGroupDetails({ isOpen, onClose, data }) {
    const history = useHistory();
    const client = useQueryClient();
    const initialRef = useRef();
    const { mutate, isLoading } = useMutation(mutateFunction);
    const [image, setImage] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [updateGroupData, setUpdateGroupData] = useState(data);

    useEffect(()=> {
        if (data) {
            setUpdateGroupData(data);
        }
    },[data])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdateGroupData({ ...updateGroupData, [name]: value });
    }


    const handleChangeForFile = (e) => {
        const fileData = e.target.files[0];
        setImage(fileData);
        setUpdateGroupData((prev) => ({
            ...prev, 
            picture: fileData
        }));
        
    };

    const updateGroupInfo = () => {
        // convert updateGroupData to a formdata object
        const formData = new FormData();
        for (const key in updateGroupData) {
            formData.append(key, updateGroupData[key]);
          }


		mutate(
			{key: `groups/${data.id}`, method: "put", data: formData},
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
                    client.invalidateQueries({ queryKey: [`groups/${data.id}`] })
                    onClose();
				}
			}
		)
	}
    useEffect(()=>{
        if(data){
            setUpdateGroupData({name: data?.name, description: data?.description, picture: data?.picture});
        }
    },[data])

    return (
        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                    <ModalHeader mt={10}>
                        <Heading>
                        Update Group Info
                        </Heading>
                    </ModalHeader>
                    <CloseButton _hover={{bg: "#5D1AB2"}} />
                    <ModalBody pl={10}>
                        <VStack spacing={5} w="100%" alignItems="flex-start">

                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Group Name</Text>
                            <Input
                            ref={initialRef}
                            type="text"
                            placeholder="Group Name"
                            name="name"
                            width="100%"
                            value={updateGroupData?.name}
                            defaultValue={updateGroupData?.name}
                            onChange={handleChange}
                            autoSave={false}
                            />
                        </VStack>
                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Description</Text>
                            <Textarea
                                type="text"
                                placeholder="Description"
                                name="description"
                                width="90%"
                                size="xl"
                                bg="#eee"
                                value={updateGroupData?.description}
                                onChange={handleChange}
                                autoSave={false}

                            />
                        </VStack>
                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Group Image</Text>
                            <Input accept="image/*" hidden id="image-input" type="file" onChange={(e) => handleChangeForFile(e)} name="image" key="image" />
                            
                            <VStack alignItems="flex-start">
                                <Flex w="200px" h="100px" justifyContent="center" alignItems="center" bg="lightgray" borderRadius="10px">
                                {(image) && <Image src={window.URL.createObjectURL(new Blob([image], { type: "image" }))} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                                {(!image && data?.picture) && <Image src={data?.picture} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                                {(!image && !data?.picture) && <Box w="200px" h="100px" borderRadius="10px" bg="lightgrey">
                                    <Center w='full' h="full">
                                        <Icon as={BsCardImage} boxSize='50px' color='gray' />
                                    </Center>
                                </Box>}
                                </Flex>
                            </VStack>
                            <label htmlFor="image-input">
                                <Box cursor="pointer" w="150px" h="30px" border="2px" borderColor="gray" borderRadius="30px"><Text  px={4} color="gray">Choose Image</Text></Box>
                            </label>
                            
                        </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
						<Button onClick={()=> updateGroupInfo()} disabled={isLoading} type="submit" w="90%" h="60px" _hover={{bg: "#5D1AB2"}} mr={9}>
							{isLoading ? <Spinner /> : <Text fontSize="20px">Update</Text>}
						</Button>
					</ModalFooter>
            </ModalContent>
        </Modal>
    )
}