import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	Button,
	ButtonGroup
} from '@chakra-ui/react';

import { CloseButton, Spinner } from 'components';

//APIs from react-form-hooks for form validations
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

//Schemas
import { createGroupSchema } from 'validators';

export default function EditGroup({ selected, setSelected, isOpen, onClose }) {
	const {
		_register,
		_handleSubmit,
		formState: { _errors },
	} = useForm({
		resolver: yupResolver(createGroupSchema),
		defaultValues: { name: selected.name },
	});
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const handleDeactivate = () => {
		const value = {status: "inactive"}
		mutate(
			{ key: `groups/${selected.id}`, method: 'put', data: value },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('groups');
					setSelected(null);
					onClose();
				},
			}
		);
	}

	const handleActivate = () => {
		const value ={status: "active"}
		mutate(
			{ key: `groups/${selected.id}`, method: 'put', data: value },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('groups');
					setSelected(null);
					onClose();
				},
			}
		);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} p={2}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Change Status</ModalHeader>
				<CloseButton w={7} h={7} _hover={{bg: "#5D1AB2"}}/>
				<ModalBody mb={4}>
					<ButtonGroup gap='2' display="flex" justifyContent="center">
						<Button disabled={isLoading} type="submit" onClick={handleDeactivate} _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Deactivate'}
						</Button>
						<Button disabled={isLoading} type="submit" onClick={handleActivate} _hover={{bg: "#5D1AB2"}}>
							{isLoading ? <Spinner /> : 'Activate'}
						</Button>
					</ButtonGroup>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
