import {
	Box,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Text,
	Center,
	Image,
	Input,
	VStack,
	Textarea,
	Icon,
	Flex,
	Heading
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { InputField, ValidationError, CloseButton, Loading } from 'components';

//APIs from react-form-hooks for form validations
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, mutateFunction } from 'libs/apis';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

//Schemas
import { createGroupSchema } from 'validators';
import { BsCardImage } from 'react-icons/bs';

export default function CreateGroup({ isOpen, onClose }) {
	const [image, setImage] = useState();
	const handleChangeForFile = (e) => {
        const fileData = e.target.files[0];
        setImage(fileData);
      };
	const initialRef = useRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue
	} = useForm({
		resolver: yupResolver(createGroupSchema),
	});
	const { mutate, isLoading } = useMutation(mutateFunction);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const createGroup = (values) => {
		// values.picture = image;
		const formData = new FormData();
        for (const key in values) {
            formData.append(key, values[key]);
        }
		formData.append("picture", image);
		mutate(
			{ key: 'groups', method: 'post', data: formData },
			{
				onSuccess(res) {
					enqueueSnackbar(res.message, { variant: 'success' });
					client.invalidateQueries('groups');
					setValue("description", "")
					setValue("name", "")
					setImage(null)
					onClose();
				},
			}
		);
	};


	return (
		<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />
			<ModalContent p={2}>
			<ModalHeader mt={10}>
				<Heading>
				Add New Group
				</Heading>
			</ModalHeader>
				<form onSubmit={handleSubmit(createGroup)}>
					<ModalCloseButton color='#000' bg='transparent' border='none' _hover={{ color: '#5D1AB2', border: '1px solid #5D1AB2'}} />
					<ModalBody p={'2em'} mt={'0.5em'}>
						<VStack spacing={5} w="100%" alignItems="flex-start">

                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Group Name</Text>
                            <Input
                            {...register("name")} 
							// ref={initialRef}
                            type="text"
                            placeholder="Group Name"
                            // name="name" 
                            width="100%"
                            
                            // onChange={onChange}
                            autoSave={false}
                            />
							<ValidationError message={errors.name?.message} />
                        </VStack>
                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Description</Text>
                            <Textarea
                                type="text"
                                placeholder="Description"
                                // name="description"
								{...register("description")}
                                width="90%"
                                size="xl"
                                bg="#eee"
                                maxLength={255}
                                // onChange={onChange}
                                autoSave={false}
                            />
							<ValidationError message={errors.description?.message} />
                        </VStack>
                        <VStack alignItems="flex-start" w="100%">
                            <Text fontWeight={500}>Group Image</Text>
                            <Input accept="image/*" hidden id="image-input" type="file" name="picture" key="image" {...register('picture')} onChange={(e)=> handleChangeForFile(e)} />
                            <ValidationError message={errors.picture?.message} />
                            <VStack alignItems="flex-start">
                                <Flex w="200px" h="100px" justifyContent="center" alignItems="center" bg="lightgray" borderRadius="10px">
                                {(image) && <Image src={window.URL.createObjectURL(new Blob([image], { type: "image" })) || image} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                                {!(image) && <Box w="200px" h="100px" borderRadius="10px" bg="lightgrey">
                                    <Center w='full' h="full">
                                        <Icon as={BsCardImage} boxSize='50px' color='gray' />
                                    </Center>
                                </Box>}
                                </Flex>
                            </VStack>
                            <label htmlFor="image-input">
                                <Box cursor="pointer" w="150px" h="30px" border="2px" borderColor="gray" borderRadius="30px"><Text  px={4} color="gray">Choose Image</Text></Box>
                            </label>
                            
                        </VStack>
						<Button h="60px" w="90%" m={0} my='1.5em' type="submit" _hover={{bg: "#5D1AB2"}}>
								{isLoading ? <Loading /> : <Text fontSize="20px">Add group</Text>}
						</Button>
                        </VStack>
					</ModalBody>
				</form>
			</ModalContent>
		</Modal>
	);
}
