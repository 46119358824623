import { useState } from "react";

const SortByColumn = (column, data, setData, asc, setAsc, sortedColumn, setSortedColumn) => {
    if (column === 'age'){
        if (sortedColumn === column) {
            if (asc) {
                setData(data?.sort((a, b) => Number(a[column]) - Number(b[column])))
            } else {
                setData(data?.sort((a, b) => Number(b[column]) - Number(a[column])))
            }
        } else {
            setData(data?.sort((a, b) => Number(a[column]) - Number(b[column])))
        }
    } else if (column === 'last_seen' || column === 'lastSeen'){
        if (sortedColumn === column) {
            if (asc) {
                setData(data?.sort((a, b) => new Date(a[column]).getTime() - new Date(b[column]).getTime()))
            } else {
                setData(data?.sort((a, b) => new Date(b[column]).getTime() - new Date(a[column]).getTime()))
            }
        } else {
            setData(data?.sort((a, b) => new Date(a[column]).getTime() - new Date(b[column]).getTime()))
        }
    }
    
    else if (sortedColumn === column) {
        if (asc) {
            setData(data?.sort((a, b) => a[column]?.toString()?.localeCompare(b[column])))
        } else{
            setData(data?.sort((a, b) => b[column]?.toString()?.localeCompare(a[column])))
        }
    } else{
        setData(data?.sort((a, b) => b[column]?.toString()?.localeCompare(a[column])))
    }

    setSortedColumn(column);
    setAsc(!asc)
}
export default SortByColumn;