import { Box, Icon } from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
} from "@chakra-ui/react";

export default function TableAction({ deleteGroup, editGroup, onRowSelected }) {

	const listStyle = {
		padding: 4,
		width: "150px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "0px",
	}

	const itemStyle = {
		color: "#5D1AB2",
		border: "none",
		padding: 4,
		paddingLeft: 8,
		background: "#fff",
		float: "right",
		textAlign: "right",
		"&:hover": {
			background: "#eee"
		},
	}

	return (
		<Box>
			<Menu escape>
				<MenuButton style={{background: "transparent", border: "none", color: "#000"}}>
					<Icon as={BiDotsHorizontalRounded} w={6} h={6}/>
				</MenuButton>
				<MenuList style={listStyle} boxShadow="0px 20px 40px rgba(93, 26, 178, 0.15)">
					<MenuItem
						_focus={{ outline: 'none' }}
						_hover={{ bg: 'gray.400' }}
						style={itemStyle}
						onClick={() => onRowSelected && onRowSelected()}
					>
						View
					</MenuItem>
					<MenuDivider />
					<MenuItem
						_focus={{ outline: 'none' }}
						style={itemStyle}
						onClick={(e) => {
							e.stopPropagation();
							editGroup();
						}}
					>
						Change Status
					</MenuItem>
					<MenuDivider />
					<MenuItem
						_focus={{ outline: 'none' }}
						style={itemStyle}
						border="none"
						onClick={(e) => {
							e.stopPropagation();
							deleteGroup();
						}}
					>
						Delete
					</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
}