import { CHURCH_APP_AUTH_USER, CHURCH_UI_THEME } from 'helpers/constants';

export const getAuthUser = () => {
	const user = localStorage.getItem(CHURCH_APP_AUTH_USER);
	return user ? JSON.parse(user) : '';
};
export const getUITheme = () => {
	const theme = localStorage.getItem(CHURCH_UI_THEME);
	return theme ? JSON.parse(theme) : '';
};
export const setAuthUser = (user) => {
	localStorage.setItem(CHURCH_APP_AUTH_USER, JSON.stringify(user));
};
export const removeAuthUser = () => {
	localStorage.removeItem(CHURCH_APP_AUTH_USER);
};
export const getSystemNotifications = () => {
	const systemNotifications = localStorage.getItem("system_notifications");
	return systemNotifications ? JSON.parse(systemNotifications) : '';
};
export const setSystemNotifications = (systemNotifications) => {
	localStorage.setItem("system_notifications", JSON.stringify(systemNotifications));
};
export const removeSystemNotifications = () => {
	localStorage.removeItem("system_notifications");
};

export const getAuthUserRole = () => getAuthUser()?.role;

export const setTheme = (theme) => {
	localStorage.setItem(CHURCH_UI_THEME, JSON.stringify(theme));
};
export const updateChurchLogo = (logo) => {

	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, logo } });
};
export const updateYoutubeChannel = (youtubeChannelLink) => {
	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, youtube_channel_link: youtubeChannelLink } });
};
export const updateDonationLink = (paypalDonationLink) => {
	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, paypal_donation_link: paypalDonationLink } });
};
export const updatePrayerRequestEmail = (prayerRequestEmail) => {
	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, prayer_request_email: prayerRequestEmail } });
};

export const updatePastorReportEmail = (pastorReportEmail) => {
	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, pastor_report_email: pastorReportEmail } });
};

export const updateGiftAidTermsDescription = (giftAidTermsDescription) => {
	const user = getAuthUser();
	setAuthUser({ ...user, church: { ...user?.church, gift_aid_terms_description: giftAidTermsDescription } });
};

